<template>


<select class="select__control" name="lang" @change="setLocale($event)">
	<option v-for="(lang, idx) in langs" :key="idx"  :value="lang.shortName" :selected="lang.shortName === currentLang">{{ lang.shortName }}</option>
</select>

</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
	  currentLang: localStorage.getItem("lang") || "ru",
      langs: [
        {
          shortName: "uk",
          longName: "Ukrainian",
          localName: "Украинский",
          flag: "/flag_uk.svg"
        },
        {
          shortName: "en",
          longName: "English",
          localName: "English",
          flag: "/flag_en.svg"
        },
        {
          shortName: "ru",
          longName: "Russian",
          localName: "Русский",
          flag: "/flag_ru.svg"
        },
        {
          shortName: "ar",
          longName: "Arabic",
          localName: "Հայոց լեզու",
          flag: "/flag_ar.svg"
        },
        {
          shortName: "fa",
          longName: "Farsi",
          localName: "زبان فارسي",
          flag: "/flag_fa.svg"
        }
      ]
    };
  },
  methods: {
    setLocale(event) {
	var locale = event.target.value;
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);
    }
  }
};
</script>

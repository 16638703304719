<template>
	<section class="">
		<!--root.begin-->
		<div class="root">
		<!--navbar.begin-->
		<nav class="navbar">
			<div class="container navbar__container">
				<a href="/" class="logo navbar__logo">
					<img class="logo__img" src="@/assets/img/logo.svg" alt="Stability">
				</a>

				<div class="navbar__collapse">
					<div class="navbar__head">
						<div class="navbar__menu-name">{{$t('menu')}}</div>
						<button class="navbar__close"></button>
					</div>
					<div class="navbar__body">

						<!--menu.begin-->
						<div class="menu navbar__menu">
							<a href="/swap" class="menu__link">{{$t('swaptitle')}}</a>
							<a href="/wallet" class="menu__link">{{$t('wallettitle')}}</a>
							<a href="/stake" class="menu__link">{{$t('staketitle')}}</a>
						</div>
						<!--menu.end-->

					</div>
				</div>
				<div class="navbar__right">

					<!--lang.begin-->
					<div class="select navbar__lang">
					<LanguageSwitcher />
					</div>
					<!--lang.end-->

					<!--btn.begin-->
					<a href="/swap" class="btn navbar__btn">{{$t('signintitle')}}</a>
					<!--btn.end-->

					<!--hamburger.begin-->
					<button class="navbar__hamburger">
						<span class="navbar__hamburger-line"></span>
						<span class="navbar__hamburger-line"></span>
						<span class="navbar__hamburger-line"></span>
					</button>
					<!--hamburger.end-->

				</div>
			</div>
		</nav>
		<!--navbar.end-->

		<!--header.begin-->
		<header class="header">
			<img class="header__mobile-img" src="@/assets/img/header/header-mobile.jpg" alt="">
			<div class="container header__container">
				<div class="header__content">
					<h1 class="title header__title">{{$t('headertitle')}}</h1>
					<p class="lead header__lead">
						{{$t('headerlead')}}
					</p>
					<a href="/swap" class="btn header__btn">{{$t('headerbtn')}}</a>
				</div>
			</div>
		</header>
		<!--header.end-->

		<!--content.begin-->
		<main class="main">

			<!--who.begin-->
			<section class="who">
				<img class="who__img" src="@/assets/img/who/who.jpg" alt="">
				<div class="container who__container">
					<div class="who__content">
						<h2 class="title who__title">
							{{$t('who__title')}}
						</h2>
						<div class="who__text">
							<h3 class="subtitle who__subtitle">{{$t('who__subtitle')}}</h3>
							<p class="lead who__lead">{{$t('who__lead')}}</p>

							<h3 class="subtitle who__subtitle">{{$t('who__subtitle1')}}</h3>
							<p class="lead who__lead">{{$t('who__lead1')}}</p>
						</div>
					</div>
				</div>
			</section>
			<!--who.end-->

			<!--benefits.begin-->
			<section class="benefits">
				<div class="container benefits__container">
					<div class="benefits__head">
						<h2 class="title benefits__title">{{$t('benefits__title')}}</h2>
						<p class="lead benefits__lead">{{$t('benefits__lead')}}</p>
					</div>
					<div class="benefits__body">
						<div class="benefits__columns">
							<div class="benefits__column">
								<div class="benefits__item">
									<div class="benefits__pict">
										<img class="benefits__img" src="@/assets/img/benefits/01.svg" alt="">
									</div>
									<p class="benefits__caption">{{$t('benefits__caption')}}</p>
								</div>
							</div>
							<div class="benefits__column">
								<div class="benefits__item">
									<div class="benefits__pict">
										<img class="benefits__img" src="@/assets/img/benefits/02.svg" alt="">
									</div>
									<p class="benefits__caption">{{$t('benefits__caption1')}}</p>
								</div>
							</div>
							<div class="benefits__column">
								<div class="benefits__item">
									<div class="benefits__pict">
										<img class="benefits__img" src="@/assets/img/benefits/03.svg" alt="">
									</div>
									<p class="benefits__caption">{{$t('benefits__caption2')}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!--benefits.end-->

			<!--exchange.begin-->
			<section class="exchange">
				<div class="container exchange__container">
					<div class="exchange__columns">
						<div class="exchange__column">
							<div class="exchange__item">
								<h2 class="title exchange__title">
									{{$t('exchange__title')}}
								</h2>
								<ul class="exchange__list">
									<li class="exchange__list-item">{{$t('exchange__list-item')}}</li>
									<li class="exchange__list-item">{{$t('exchange__list-item1')}}</li>
									<li class="exchange__list-item">{{$t('exchange__list-item2')}}</li>
									<li class="exchange__list-item">{{$t('exchange__list-item3')}}</li>
								</ul>
								<a href="#" class="btn exchange__btn">{{$t('exchange__btn')}}</a>
							</div>
						</div>
						<div class="exchange__column">
							<div class="exchange__item">
								<h2 class="title exchange__title">
									{{$t('exchange__title1')}}
								</h2>
								<ul class="exchange__list">
									<li class="exchange__list-item">{{$t('exchange__list-item4')}}</li>
									<li class="exchange__list-item">{{$t('exchange__list-item5')}}</li>
									<li class="exchange__list-item">{{$t('exchange__list-item6')}}</li>
									<li class="exchange__list-item">{{$t('exchange__list-item7')}}</li>
								</ul>
								<a href="#" class="btn exchange__btn">{{$t('exchange__btn1')}}</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!--exchange.end-->

			<!--join.begin-->
			<section class="join">
				<div class="container join__container">
					<div class="join__content">
						<h2 class="title join__title">{{$t('join__title')}}</h2>
						<p class="lead join__lead">
							{{$t('join__lead')}}
						</p>
						<a href="/swap" class="btn join__btn">{{$t('join__btn')}}</a>
					</div>
				</div>
				<img class="join__mobile-img " src="@/assets/img/join/join-mobile.jpg" alt="">
			</section>
			<!--join.end-->

		</main>
		<!--content.end-->

		<!--footer.begin-->
		<footer class="footer">
			<div class="container footer__container">
				<div class="footer__columns">
					<div class="footer__column footer__column--1">
						<div class="footer__item">
							<a href="/" class="logo footer__logo">
								<img class="logo__img" src="@/assets/img/logo.svg" alt="Stability">
							</a>
							<div class="footer__desktop">
								<div class="footer__copyright">
									©2021. Between Ocean. {{$t('footer__copyright')}}
								</div>
								<a href="#" class="footer__link">{{$t('footer__link4')}}</a>
								<a href="#" class="footer__link">{{$t('footer__link5')}}</a>
							</div>
						</div>
					</div>
					<div class="footer__column footer__column--2">
						<div class="footer__item">
							<div class="footer__menu">
								<h3 class="footer__title">{{$t('footer__title')}}</h3>
								<a href="#" class="footer__link">{{$t('footer__link')}}</a>
								<a href="#" class="footer__link">{{$t('footer__link1')}}</a>
								<a href="#" class="footer__link">{{$t('footer__link2')}}</a>
								<a href="#" class="footer__link">{{$t('footer__link3')}}</a>
							</div>
							<div class="footer__menu">
								<h3 class="footer__title">{{$t('footer__title1')}}</h3>
								<a :href="$t('partner_link')" class="footer__link" target="_blank">{{$t('partner_title')}}</a>
								<a href="#" class="footer__link">{{$t('exchange__title')}}</a>
								<a href="#" class="footer__link">{{$t('exchange__title1')}}</a>
							</div>
						</div>
					</div>
					<div class="footer__column footer__column--3">
						<div class="footer__item">
							<div class="footer__menu">
								<h3 class="footer__title">{{$t('footer__title2')}}</h3>

								<div class="footer__social">
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-tg"></use>
										</svg>
										Telegram
									</a>
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-fb"></use>
										</svg>
										Facebook
									</a>
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-vk"></use>
										</svg>
										VK
									</a>
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-instagram"></use>
										</svg>
										Instagram
									</a>
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-discord"></use>
										</svg>
										Discord
									</a>
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-reddit"></use>
										</svg>
										Reddit
									</a>
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-medium"></use>
										</svg>
										Medium
									</a>
									<a href="#" class="footer__link">
										<svg class="footer__link-icon">
											<use xlink:href="#i-youtube"></use>
										</svg>
										Youtube
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="footer__column footer__column--4">
						<div class="footer__item">
							<div class="footer__menu">
								<h3 class="footer__title">{{$t('footer__title3')}}</h3>
								<a href="/swap" class="footer__link">{{$t('signintitle')}}</a>
								<div class="footer__lang">
									<span class="footer__lang-text">{{$t('language')}}:</span>
									<LanguageSwitcher />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="footer__mobile">
					<div class="footer__copyright">
						©2021. Between Ocean. {{$t('footer__copyright')}}
					</div>
					<a href="#" class="footer__link">{{$t('footer__link4')}}</a>
					<a href="#" class="footer__link">{{$t('footer__link5')}}</a>
				</div>
			</div>
		</footer>
		<!--footer.end-->

	</div>
		<!--root.end-->

		<!-- svg sprites.begin -->
		<svg class="sr-only" version="1.1" height="0" xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink">
			<symbol id="i-tg" viewBox="0 0 24 24" fill="none">
				<path d="M20.6646 3.71706L2.93461 10.5541C1.72461 11.0401 1.73161 11.7151 2.71261 12.0161L7.26461 13.4361L17.7966 6.79106C18.2946 6.48806 18.7496 6.65106 18.3756 6.98306L9.84261 14.6841H9.84061L9.84261 14.6851L9.52861 19.3771C9.98861 19.3771 10.1916 19.1661 10.4496 18.9171L12.6606 16.7671L17.2596 20.1641C18.1076 20.6311 18.7166 20.3911 18.9276 19.3791L21.9466 5.15106C22.2556 3.91206 21.4736 3.35106 20.6646 3.71706Z" fill="currentColor"/>
			</symbol>
			<symbol id="i-fb" viewBox="0 0 24 24" fill="none">
				<path d="M13.397 20.9969V12.8009H16.162L16.573 9.59191H13.397V7.54791C13.397 6.62191 13.655 5.98791 14.984 5.98791H16.668V3.12691C15.849 3.03891 15.025 2.99691 14.201 2.99991C11.757 2.99991 10.079 4.49191 10.079 7.23091V9.58591H7.33203V12.7949H10.085V20.9969H13.397Z" fill="currentColor"/>
			</symbol>
			<symbol id="i-vk" viewBox="0 0 24 24" fill="none">
				<path d="M21.579 6.85507C21.719 6.39007 21.579 6.04907 20.917 6.04907H18.724C18.166 6.04907 17.911 6.34407 17.771 6.66807C17.771 6.66807 16.656 9.38707 15.076 11.1501C14.566 11.6631 14.333 11.8251 14.055 11.8251C13.916 11.8251 13.714 11.6631 13.714 11.1981V6.85507C13.714 6.29707 13.553 6.04907 13.088 6.04907H9.642C9.294 6.04907 9.084 6.30707 9.084 6.55307C9.084 7.08107 9.874 7.20307 9.955 8.69107V11.9191C9.955 12.6261 9.828 12.7551 9.548 12.7551C8.805 12.7551 6.997 10.0261 5.924 6.90207C5.715 6.29507 5.504 6.05007 4.944 6.05007H2.752C2.125 6.05007 2 6.34507 2 6.66907C2 7.25107 2.743 10.1311 5.461 13.9401C7.273 16.5411 9.824 17.9511 12.148 17.9511C13.541 17.9511 13.713 17.6381 13.713 17.0981V15.1321C13.713 14.5061 13.846 14.3801 14.287 14.3801C14.611 14.3801 15.169 14.5441 16.47 15.7971C17.956 17.2831 18.202 17.9501 19.037 17.9501H21.229C21.855 17.9501 22.168 17.6371 21.988 17.0191C21.791 16.4041 21.081 15.5091 20.139 14.4501C19.627 13.8461 18.862 13.1961 18.629 12.8711C18.304 12.4521 18.398 12.2671 18.629 11.8951C18.63 11.8961 21.301 8.13407 21.579 6.85507Z" fill="currentColor"/>
			</symbol>
			<symbol id="i-instagram" viewBox="0 0 24 24" fill="none">
				<path d="M11.999 7.37695C9.44498 7.37695 7.37598 9.44695 7.37598 12C7.37598 14.554 9.44498 16.624 11.999 16.624C14.551 16.624 16.622 14.554 16.622 12C16.622 9.44695 14.551 7.37695 11.999 7.37695ZM11.999 15.004C10.34 15.004 8.99498 13.659 8.99498 12.001C8.99498 10.342 10.34 8.99795 11.999 8.99795C13.658 8.99795 15.001 10.342 15.001 12.001C15.001 13.659 13.658 15.004 11.999 15.004Z" fill="currentColor"/>
				<path d="M16.8055 8.28491C17.4009 8.28491 17.8835 7.80227 17.8835 7.20691C17.8835 6.61154 17.4009 6.12891 16.8055 6.12891C16.2102 6.12891 15.7275 6.61154 15.7275 7.20691C15.7275 7.80227 16.2102 8.28491 16.8055 8.28491Z" fill="currentColor"/>
				<path d="M20.533 6.11088C20.064 4.90188 19.109 3.94588 17.9 3.47888C17.201 3.21588 16.462 3.07488 15.714 3.05888C14.751 3.01688 14.446 3.00488 12.004 3.00488C9.56195 3.00488 9.24895 3.00488 8.29395 3.05888C7.54795 3.07388 6.80895 3.21488 6.10995 3.47888C4.89995 3.94588 3.94495 4.90188 3.47695 6.11088C3.21395 6.81088 3.07295 7.54888 3.05795 8.29688C3.01495 9.25888 3.00195 9.56388 3.00195 12.0069C3.00195 14.4489 3.00195 14.7599 3.05795 15.7169C3.07295 16.4649 3.21395 17.2029 3.47695 17.9039C3.94595 19.1119 4.90095 20.0679 6.11095 20.5359C6.80695 20.8079 7.54595 20.9619 8.29595 20.9859C9.25895 21.0279 9.56395 21.0409 12.006 21.0409C14.448 21.0409 14.761 21.0409 15.716 20.9859C16.463 20.9709 17.202 20.8289 17.902 20.5669C19.111 20.0979 20.066 19.1429 20.535 17.9339C20.798 17.2339 20.939 16.4959 20.954 15.7479C20.997 14.7859 21.01 14.4809 21.01 12.0379C21.01 9.59488 21.01 9.28488 20.954 8.32788C20.941 7.56988 20.801 6.81888 20.533 6.11088ZM19.315 15.6429C19.308 16.2189 19.204 16.7899 19.004 17.3309C18.699 18.1179 18.078 18.7399 17.292 19.0419C16.757 19.2409 16.193 19.3449 15.622 19.3529C14.672 19.3969 14.404 19.4079 11.968 19.4079C9.52995 19.4079 9.28095 19.4079 8.31295 19.3529C7.74395 19.3459 7.17795 19.2409 6.64395 19.0419C5.85495 18.7409 5.22995 18.1189 4.92495 17.3309C4.72895 16.7969 4.62295 16.2319 4.61395 15.6619C4.57095 14.7119 4.56095 14.4439 4.56095 12.0079C4.56095 9.57088 4.56095 9.32188 4.61395 8.35288C4.62095 7.77688 4.72495 7.20688 4.92495 6.66588C5.22995 5.87688 5.85495 5.25588 6.64395 4.95388C7.17795 4.75588 7.74395 4.65088 8.31295 4.64288C9.26395 4.59988 9.53095 4.58788 11.968 4.58788C14.405 4.58788 14.655 4.58788 15.622 4.64288C16.193 4.64988 16.757 4.75488 17.292 4.95388C18.078 5.25688 18.699 5.87888 19.004 6.66588C19.2 7.19988 19.306 7.76488 19.315 8.33488C19.358 9.28588 19.369 9.55288 19.369 11.9899C19.369 14.4259 19.369 14.6879 19.326 15.6439H19.315V15.6429Z" fill="currentColor"/>
			</symbol>
			<symbol id="i-discord" viewBox="0 0 24 24" fill="none">
				<path d="M9.59302 10.9709C9.05102 10.9709 8.62402 11.4459 8.62402 12.0259C8.62402 12.6039 9.06102 13.0809 9.59302 13.0809C10.134 13.0809 10.561 12.6039 10.561 12.0259C10.572 11.4449 10.134 10.9709 9.59302 10.9709ZM13.061 10.9709C12.519 10.9709 12.092 11.4459 12.092 12.0259C12.092 12.6039 12.529 13.0809 13.061 13.0809C13.602 13.0809 14.029 12.6039 14.029 12.0259C14.028 11.4449 13.602 10.9709 13.061 10.9709Z" fill="currentColor"/>
				<path d="M17.678 3H4.947C3.874 3 3 3.874 3 4.957V17.801C3 18.884 3.874 19.758 4.947 19.758H15.72L15.215 17.999L16.432 19.13L17.581 20.194L19.625 22V4.957C19.625 3.874 18.751 3 17.678 3ZM14.01 15.407C14.01 15.407 13.668 14.999 13.384 14.636C14.628 14.284 15.103 13.506 15.103 13.506C14.713 13.762 14.343 13.944 14.01 14.068C13.535 14.266 13.08 14.399 12.633 14.476C11.721 14.646 10.885 14.599 10.172 14.466C9.631 14.361 9.166 14.209 8.776 14.056C8.558 13.971 8.319 13.868 8.083 13.735C8.054 13.714 8.026 13.706 7.998 13.687C7.978 13.677 7.969 13.668 7.959 13.657C7.788 13.563 7.693 13.497 7.693 13.497C7.693 13.497 8.149 14.257 9.356 14.618C9.071 14.978 8.719 15.407 8.719 15.407C6.62 15.34 5.823 13.963 5.823 13.963C5.823 10.904 7.191 8.425 7.191 8.425C8.559 7.398 9.86 7.427 9.86 7.427L9.955 7.541C8.245 8.036 7.456 8.786 7.456 8.786C7.456 8.786 7.666 8.672 8.017 8.511C9.033 8.065 9.84 7.941 10.173 7.912C10.23 7.903 10.278 7.893 10.335 7.893C10.915 7.817 11.57 7.798 12.253 7.874C13.156 7.979 14.126 8.245 15.113 8.786C15.113 8.786 14.362 8.074 12.747 7.58L12.88 7.428C12.88 7.428 14.182 7.399 15.549 8.426C15.549 8.426 16.917 10.905 16.917 13.964C16.917 13.963 16.11 15.34 14.01 15.407Z" fill="currentColor"/>
			</symbol>
			<symbol id="i-reddit" viewBox="0 0 24 24" fill="none">
				<path d="M9.66995 14.001C10.2228 14.001 10.6709 13.5529 10.6709 13C10.6709 12.4472 10.2228 11.999 9.66995 11.999C9.11711 11.999 8.66895 12.4472 8.66895 13C8.66895 13.5529 9.11711 14.001 9.66995 14.001Z" fill="currentColor"/>
				<path d="M14.0901 15.391C13.4821 15.826 12.7461 16.041 12.0001 16C11.2561 16.034 10.5211 15.811 9.91906 15.37C9.82006 15.289 9.67706 15.289 9.57706 15.37C9.46206 15.465 9.44506 15.635 9.54006 15.75C10.2501 16.285 11.1221 16.559 12.0111 16.52C12.8981 16.559 13.7711 16.287 14.4801 15.75V15.79C14.5891 15.684 14.5911 15.505 14.4861 15.394C14.3771 15.284 14.2001 15.282 14.0901 15.391ZM14.2991 12.04C13.7471 12.04 13.3001 12.488 13.3001 13.041C13.3001 13.593 13.7471 14.04 14.2991 14.04L14.2911 14.079C14.3071 14.081 14.3241 14.079 14.3421 14.079C14.8931 14.058 15.3211 13.592 15.3001 13.041C15.3001 12.488 14.8541 12.04 14.2991 12.04Z" fill="currentColor"/>
				<path d="M12 2C6.479 2 2 6.477 2 12C2 17.521 6.479 22 12 22C17.521 22 22 17.521 22 12C22 6.477 17.521 2 12 2ZM17.859 13.33C17.871 13.476 17.871 13.623 17.859 13.769C17.859 16.009 15.25 17.831 12.029 17.831C8.808 17.831 6.199 16.011 6.199 13.769C6.187 13.623 6.187 13.476 6.199 13.33C6.054 13.264 5.924 13.175 5.807 13.066C5.22 12.513 5.193 11.588 5.744 11.003C6.296 10.415 7.221 10.387 7.807 10.94C8.959 10.159 10.316 9.731 11.706 9.71L12.449 6.24C12.449 6.239 12.449 6.238 12.449 6.236C12.487 6.069 12.652 5.964 12.82 6L15.269 6.49C15.429 6.215 15.709 6.032 16.027 5.998C16.577 5.939 17.068 6.336 17.129 6.886C17.188 7.435 16.791 7.928 16.24 7.987C15.691 8.046 15.199 7.649 15.138 7.1L13 6.65L12.351 9.77C13.726 9.799 15.062 10.227 16.201 11C16.46 10.752 16.8 10.607 17.158 10.594C17.965 10.565 18.64 11.194 18.67 12C18.68 12.563 18.363 13.08 17.859 13.33Z" fill="currentColor"/>
			</symbol>
			<symbol id="i-medium" viewBox="0 0 24 24" fill="none">
				<path d="M4.28513 7.26902C4.30813 7.03702 4.22013 6.80602 4.04513 6.65002L2.27513 4.51702V4.19702H7.77313L12.0231 13.517L15.7591 4.19702H21.0001V4.51602L19.4851 5.96702C19.3551 6.06702 19.2921 6.23102 19.3171 6.39202V17.058C19.2921 17.22 19.3551 17.383 19.4851 17.483L20.9641 18.934V19.253H13.5281V18.934L15.0571 17.447C15.2091 17.297 15.2091 17.252 15.2091 17.023V8.40102L10.9501 19.218H10.3751L5.41713 8.40102V15.65C5.37613 15.955 5.47713 16.262 5.69213 16.483L7.68413 18.9V19.219H2.03613V18.9L4.02813 16.483C4.24013 16.262 4.33613 15.953 4.28513 15.65V7.26902Z" fill="currentColor"/>
			</symbol>
			<symbol id="i-youtube" viewBox="0 0 24 24" fill="none">
				<path d="M21.5933 7.20301C21.3633 6.34501 20.6883 5.66801 19.8313 5.43701C18.2653 5.00701 12.0003 5.00001 12.0003 5.00001C12.0003 5.00001 5.73633 4.99301 4.16933 5.40401C3.32933 5.63301 2.63533 6.32501 2.40333 7.18201C1.99033 8.74801 1.98633 11.996 1.98633 11.996C1.98633 11.996 1.98233 15.26 2.39233 16.81C2.62233 17.667 3.29733 18.344 4.15533 18.575C5.73733 19.005 11.9853 19.012 11.9853 19.012C11.9853 19.012 18.2503 19.019 19.8163 18.609C20.6723 18.379 21.3503 17.703 21.5833 16.846C21.9973 15.281 22.0003 12.034 22.0003 12.034C22.0003 12.034 22.0203 8.76901 21.5933 7.20301ZM9.99633 15.005L10.0013 9.00501L15.2083 12.01L9.99633 15.005Z" fill="currentColor"/>
			</symbol>
		</svg>
		<!-- svg sprites.end -->

	</section>
</template>

<script>
// import '@/assets/vue/css/home.css';
import '@/assets/css/style.min.css';
import LanguageSwitcher from "@/components/TopNav/LanguageSwitcher.vue";

export default {
	name: 'Home',
	data() {
		return {
			pdflink: ''
		};
	},
	methods: {
	},
	components: {
		LanguageSwitcher
	},
	mounted() {
		if(this.$route.query.invite_code) {
			localStorage.invite_code = this.$route.query.invite_code;
		}
	}
};
</script>

<style>
</style>
